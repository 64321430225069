import { Component, OnInit, ViewChild } from "@angular/core";
import {
	MatCell,
	MatCellDef,
	MatColumnDef,
	MatHeaderCell,
	MatHeaderCellDef,
	MatHeaderRow,
	MatHeaderRowDef,
	MatRow,
	MatRowDef,
	MatTable,
	MatTableDataSource,
} from "@angular/material/table";
import { Protocol } from "../../../interfaces/protocols/protocol";
import { Tag } from "../../../interfaces/tag";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, MatSortHeader } from "@angular/material/sort";
import { MatAutocomplete } from "@angular/material/autocomplete";
import { MitpManagerService } from "../../../services/mitp-manager.service";
import { MatDialog } from "@angular/material/dialog";
import { OrderByPipe, UniquePipe } from "ngx-pipes";
import * as fuzz from "fuzzball";
import { InstituteHeaderComponent } from "../../institutes/institute-header/institute-header.component";
import { ProtocolTemplateComponent } from "../../institutes/protocol-template/protocol-template.component";
import { ProtocolCodificationComponent } from "../../protocols/protocol-codification/protocol-codification.component";
import { ProtocolUpdatesComponent } from "../../protocols/protocol-updates/protocol-updates.component";
import { ProtocolViewerService } from "../../../services/protocol-viewer.service";
import { MatTooltip } from "@angular/material/tooltip";
import { MatChipListbox, MatChipOption } from "@angular/material/chips";
import { MatMenu, MatMenuItem, MatMenuTrigger } from "@angular/material/menu";
import { TagFilterComponent } from "../../tags/tag-filter/tag-filter.component";
import { NgFor, NgIf } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatInput } from "@angular/material/input";
import { MatFormField, MatLabel, MatPrefix, MatSuffix } from "@angular/material/form-field";
import { MatButton, MatIconButton } from "@angular/material/button";
import { MatCard, MatCardContent, MatCardTitle } from "@angular/material/card";
import { MatIcon } from "@angular/material/icon";
import { ProtocolService } from "../../../services/protocol.service";
import { finalize, first } from "rxjs";
import fs from "file-saver";
import { NgxTolgeeModule } from "@tolgee/ngx";
import { InstituteGlobalCodificationComponent } from "../../institutes/institute-global-codification/institute-global-codification.component";
import { InstituteService } from "../../../services/institute.service";
import { UserService } from "../../../services/user.service";

@Component({
	selector: "app-quality-dashboard",
	templateUrl: "./quality-dashboard.component.html",
	styleUrls: ["./quality-dashboard.component.scss"],
	imports: [
		MatIcon,
		MatCard,
		MatCardTitle,
		MatCardContent,
		MatButton,
		MatFormField,
		MatLabel,
		MatInput,
		ReactiveFormsModule,
		FormsModule,
		MatPrefix,
		NgIf,
		MatSuffix,
		TagFilterComponent,
		MatTable,
		MatSort,
		MatColumnDef,
		MatHeaderCellDef,
		MatHeaderCell,
		MatCellDef,
		MatCell,
		MatSortHeader,
		MatMenuTrigger,
		MatMenu,
		MatMenuItem,
		MatChipListbox,
		NgFor,
		MatChipOption,
		MatIconButton,
		MatTooltip,
		MatHeaderRowDef,
		MatHeaderRow,
		MatRowDef,
		MatRow,
		MatPaginator,
		NgxTolgeeModule,
	],
})
export class QualityDashboardComponent implements OnInit {
	isArchives = false;
	displayedColumns: string[] = ["status", "label", "tags", "actions"];
	dataSource = new MatTableDataSource<Protocol>();
	protocols_all: Protocol[] = [];
	searchKeyword = "";
	tags: Tag[] = [];
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;
	@ViewChild("auto") matAutocomplete: MatAutocomplete;
	statistics: any = [];

	constructor(
		public manager: MitpManagerService,
		private dialog: MatDialog,
		private uPipe: UniquePipe,
		private oPipe: OrderByPipe,
		private protocolViewer: ProtocolViewerService,
		private protocolService: ProtocolService,
		private instituteService: InstituteService,
		private userService: UserService
	) {}

	ngOnInit(): void {
		this.get();
	}

	filterProtocols(): void {
		let filterProtocols = this.isArchives
			? this.protocols_all.filter((e) => e.status == 2)
			: this.protocols_all.filter((e) => e.status != 2);
		let tmp = [];
		if (this.tags.length > 0) {
			filterProtocols.forEach((protocol) => {
				if (
					this.tags.every((tag) => {
						return protocol.tags.map((e) => e.id).includes(tag.id);
					})
				) {
					tmp.push(protocol);
				}
			});
			filterProtocols = tmp;
			tmp = [];
		}
		if (this.searchKeyword != "") {
			tmp = filterProtocols.filter((protocol) => {
				return fuzz.partial_ratio(protocol.label.toLowerCase(), this.searchKeyword.trim().toLowerCase()) > 90;
			});
			filterProtocols = tmp;
		}
		filterProtocols = this.uPipe.transform(filterProtocols, "id");
		filterProtocols = this.oPipe.transform(filterProtocols, "label");
		this.dataSource.data = filterProtocols;
	}

	isExpired(protocol: Protocol): boolean {
		if (protocol.updates.length > 0) {
			const lastUpdate = protocol.updates[protocol.updates.length - 1];
			let diff = new Date().getTime() - new Date(lastUpdate.updated).getTime();
			diff /= 1000 * 60 * 60 * 24 * 365;
			if (diff > 3) {
				return true;
			}
		}
		return false;
	}

	getStatusIcon(element: Protocol): string {
		switch (element.status) {
			case 1:
				return "assets/circle-green.png";
			case 0:
				return "assets/circle-red.png";
			case 2:
				return "assets/circle-blue.png";
			default:
				return "";
		}
	}

	view(element: Protocol, published: boolean): void {
		if (published) {
			this.protocolService.getPublishedProtocol(element.id).subscribe((data) => {
				this.protocolViewer.viewProtocol(element, data);
			});
		} else {
			this.protocolService.getDraftProtocol(element.id).subscribe((data) => {
				this.protocolViewer.viewProtocol(element, data);
			});
		}
	}

	loadArchives(b: boolean) {
		this.isArchives = b;
		this.filterProtocols();
	}

	editHeader(): void {
		this.dialog.open(InstituteHeaderComponent, {
			data: null,
			disableClose: true,
			minWidth: "900px",
			minHeight: "600px",
		});
	}

	editProtocolTemplate(): void {
		this.dialog.open(ProtocolTemplateComponent, {
			data: null,
			disableClose: true,
			minWidth: "900px",
			minHeight: "600px",
		});
	}

	clearFilters(): void {
		this.searchKeyword = "";
		this.tags = [];
		this.filterProtocols();
	}

	private get(): void {
		this.manager.showLoading(true);
		this.protocolService.getQualityProtocols().subscribe((protocols) => {
			this.manager.showLoading(false);
			this.protocols_all = protocols;
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
			this.filterProtocols();
		});
	}

	clearSearchKeyword(): void {
		this.searchKeyword = "";
		this.filterProtocols();
	}

	setCodification(protocol: Protocol): void {
		this.dialog
			.open(ProtocolCodificationComponent, {
				data: protocol,
				minWidth: "600px",
			})
			.afterClosed()
			.subscribe((response) => {
				if (response) {
					protocol.codification = response;
				}
			});
	}

	showHistory(protocol: Protocol): void {
		this.dialog.open(ProtocolUpdatesComponent, {
			data: protocol,
			minWidth: "600px",
		});
	}

	exportExcel(): void {
		this.manager.showLoading(true);
		this.protocolService
			.exportQualityProtocols()
			.pipe(
				first(),
				finalize(() => this.manager.showLoading(false))
			)
			.subscribe((data) => {
				const file = new Blob([data.body], {
					type: data.headers.get("Content-Type"),
				});
				fs.saveAs(file, "quality_protocols.xlsx");
			});
	}

	editGlobalCodification(): void {
		this.manager.showLoading(true);
		const instituteId = this.userService.getInstituteId();
		this.instituteService
			.getInstitute(instituteId)
			.pipe(
				first(),
				finalize(() => this.manager.showLoading(false))
			)
			.subscribe((institute) => {
				this.dialog.open(InstituteGlobalCodificationComponent, {
					data: institute,
					width: "600px",
					autoFocus: false,
					disableClose: true,
				});
			});
	}
}
